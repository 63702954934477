import Vue from "vue";
import Vuex from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import projects from "../assets/projects.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projectFilters: [],
    message: null,
    name: {
      firstName: "Markus",
      lastName: "Eßer",
      title: "Dipl.-Wirt.-Inf.",
    },
    personalData: [
      {
        title: "Anschrift",
        value: "Zwirnerstr. 39, D-50678 Köln",
        icon: "mdi-mail",
      },
      {
        title: "Telefon",
        value: "+49 160 90920111",
        icon: "mdi-phone",
      },
      {
        title: "E-Mail",
        value: "esser@inocon.com",
        icon: "mdi-at",
      },
      {
        title: "Geburtsort und -datum",
        value: "Mönchengladbach am 05.01.1975",
        icon: "mdi-calendar",
      },
      {
        title: "Familienstand",
        value: "verheiratet",
        icon: "mdi-account-multiple",
      },
    ],
    education: [
      {
        title: "Zusätzliche Qualifikationen",
        sections: [
          {
            color: "green",
            year: "2006 - 2009",
            title: "Ausbilder Fachinformatiker für Anwendungsentwicklung",
            content: [
              "Anerkennung zur Ausbildung der Ausbildungsberufe:",
              "Fachinformatiker für Anwendungsentwicklung sowie IT-Systemkaufmann",
            ],
          },
        ],
      },
      {
        title: "Ausbildung",
        sections: [
          {
            color: "cyan",
            year: "1995 - 2000",
            title: "Diplom Wirtschaftsinformatiker",
            content: [
              "Universität zu Köln",
              "<h3>Schwerpunkte</h3>",
              "Mathematische Optimierung und Operations Research sowie Decision Support Systeme und Datenbanken (Wirtschaftsinformatik)",
              "Parallele Algorithmen, Online Algorithmen (Informatik)",
              "Marketing und Marktforschung (Betriebswirtschaftslehre)",
            ],
          },
          {
            color: "green",
            year: "1994 - 1995",
            title: "Grundwehrdienst",
            content: [
              "Luftwaffe in Kerpen/Nörvenich",
              "Bau- und Betriebsfernsprecher",
            ],
          },
          {
            color: "orange",
            year: "1981 - 1994",
            title: "Abitur",
            content: [
              "Mathematisch-Naturwissenschaftliches Gymnasium Mönchengladbach",
              "<h3>Leistungskurse</h3>",
              "Mathematik und Physik",
            ],
          },
        ],
      },
    ],
    career: [
      {
        title: "Beruflicher Werdegang",
        sections: [
          {
            color: "cyan",
            year: "2001 - heute",
            title: "INOCON Solutions GmbH",
            content: [
              "Geschäftsführender Gesellschafter (CEO)",
              "<h3>Schwerpunkte</h3>",
              "Projektarbeit als Senior OR-Consultant sowie Implementierung von IT-Lösungen mit Schwerpunkt Luftfahrt",
              "<h3>Kunden</h3>",
              "Deutsche Lufthansa AG",
              "Lufthansa Cargo AG",
              "Thomas Cook AG",
              "Condor Flugdienst GmbH",
              '<a href="http://www.inocon.com" target="_blank">www.inocon.com</a>',
            ],
          },
        ],
      },
    ],
    skills: [
      {
        key: "lang",
        title: "Sprachkenntnisse",
        icon: "mdi-earth",
        ignoreFilter: true,
        values: [
          { title: "Deutsch", rating: 5.0 },
          { title: "Englisch", rating: 4.0 },
        ],
      },
      {
        key: "database",
        title: "Datenbanken",
        icon: "mdi-database",
        values: [
          { title: "PostgreSQL", rating: 5.0 },
          { title: "MS Access", rating: 5.0 },
          { title: "Cassandra", rating: 4.0 },
          { title: "SQL Server", rating: 4.5 },
          { title: "Dgraph", subtitle: "Graphdatenbank", rating: 4.0 },
          { title: "Neo4j", rating: 4.0 },
          { title: "Oracle DB", rating: 4.0 },
          { title: "SQLite", rating: 5.0 },
        ],
      },
      {
        key: "os",
        title: "Betriebssysteme und Infrastruktur",
        icon: "mdi-cog-outline",
        values: [
          { title: "Windows", rating: 5.0 },
          { title: "MacOS", rating: 5.0 },
          { title: "Unix/Linux", rating: 5.0 },
          { title: "iOS", rating: 4.5 },
          { title: "Docker", rating: 4.5 },
          { title: "AWS", rating: 3.0 },
        ],
      },
      {
        key: "coding",
        title: "Programmiersprachen",
        icon: "mdi-code-tags",
        values: [
          { title: "SQL", rating: 5.0 },
          { title: "Java", rating: 5.0 },
          { title: "C++", rating: 5.0 },
          { title: "Python", rating: 5.0 },
          { title: "Elixir", rating: 5.0 },
          { title: "JavaScript", rating: 5.0 },
          { title: "TypeScript", rating: 4.5 },
          { title: "Perl", rating: 5.0 },
          { title: "Shell Scripting", rating: 5.0 },
          { title: "Rave", rating: 5.0 },
          { title: "Delphi/Pascal", rating: 5.0 },
          { title: "C#", rating: 5.0 },
          { title: "SAS", rating: 5.0 },
          { title: "Swift", rating: 3.0 },
          { title: "TeX/LaTeX", rating: 5.0 },
          { title: "VBA", rating: 5.0 },
          { title: "PHP", rating: 3.0 },
          { title: "HTML/CSS", rating: 5.0 },
        ],
      },
      {
        key: "focus",
        title: "Schwerpunkte",
        icon: "mdi-camera-iris",
        values: [
          {
            title: "Mathematische Optimierung / Operations Research",
            rating: 5.0,
          },
          { title: "Simulation", rating: 4.0 },
          { title: "Algorithmen", rating: 5.0 },
          { title: "Event Sourcing", rating: 5.0 },
        ],
      },
      {
        key: "framework",
        title: "Frameworks",
        icon: "mdi-code-tags",
        values: [
          { title: "React.js", rating: 4.0 },
          { title: "Angular.js", rating: 4.5 },
          { title: "PySpark", rating: 4.0 },
          { title: "Entity Framework", rating: 4.0 },
          { title: "Node.js", rating: 4.0 },
          { title: "Pandas", rating: 5.0 },
        ],
      },
      {
        key: "product",
        title: "Produkte und Standards",
        icon: "mdi-apps",
        values: [
          { title: "Microsoft Office", rating: 5.0 },
          { title: "Xcode", rating: 5.0 },
        ],
      },
      {
        key: "other",
        title: "Sonstige Erfahrungen",
        icon: "mdi-apps",
        values: [],
      },
      {
        key: "industry",
        title: "Branchen",
        icon: "mdi-gesture-tap",
        values: [],
      },
      {
        key: "role",
        title: "Rollen",
        values: [],
      },
      {
        key: "client",
        title: "Kunden",
        values: [],
      },
    ],
    projects: projects,
    predefinedFilters: [
      {
        code: "c++",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text: "Die folgende Auswahl zeigt eine Auswahl an C++ Projekten.",
        },
        filters: { coding: ["C++"] },
      },
      {
        code: "csharp",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text: "Die folgende Auswahl zeigt eine Auswahl an C# Projekten.",
        },
        filters: { coding: ["C#"] },
      },
      {
        code: "vuejs",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text: "Die folgende Auswahl zeigt eine Auswahl an Vue.js Projekten.",
        },
        filters: { framework: ["Vue.js"] },
      },
      {
        code: "python",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text:
            "Die folgende Auswahl zeigt eine Auswahl an <b>Python</b> Projekten.",
        },
        filters: { coding: ["Python"] },
      },
      {
        code: "elixir",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text:
            "Die folgende Auswahl zeigt eine Auswahl an <b>Elixir</b> Projekten.",
        },
        filters: { coding: ["Elixir"] },
      },
      {
        code: "or",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text:
            "Die folgende Auswahl zeigt eine Auswahl von Projekten mit Schwerpunkt <b>Operations Research</b>.",
        },
        filters: { focus: ["Mathematische Optimierung"] },
      },
      {
        code: "lupus_cpp",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text: `Die folgende Auswahl zeigt eine Auswahl von Projekten mit Schwerpunkten zur Bewerbung auf 
            <b>LUPUS - Software Architekt im C++ Umfeld</b>.`,
        },
        filters: {
          coding: ["C++", "Python"],
          focus: ["Mathematische Optimierung"],
        },
      },
      {
        code: "solcom_61658",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text: `Die folgende Auswahl zeigt eine Auswahl von Projekten mit Schwerpunkten zur Bewerbung auf:<br/>
            <b>SOLCOM (61658) - Softwareentwickler C++, C# und .NET sowie Visual Basic</b>`,
        },
        filters: {
          coding: ["C++", "C#"],
          database: ["Microsoft SQL Server"],
        },
      },
      {
        code: "or_cpp_python",
        message: {
          title: "Vielen Dank für Ihr Interesse!",
          text: `Die folgende Auswahl zeigt eine Auswahl von Projekten mit Schwerpunkten zur Bewerbung auf:<br/>
            <b>Mathematische Optimierung, C++, Python</b>`,
        },
        filters: {
          coding: ["C++", "Python"],
          focus: ["Mathematische Optimierung"],
        },
      },
    ],
  },
  getters,
  mutations,
  actions,
  modules: {},
});
