





































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data: () => ({
    modules: [
      {
        title: "Über mich",
        description: "Einige Worte über mich selbst.",
        icon: "mdi-comment-account-outline",
        href: "/aboutme",
        color: "green--text"
      },
      {
        title: "Persönliche Daten",
        description: "Meine Kontaktdaten.",
        icon: "mdi-account",
        href: "/personal",
        color: "orange--text"
      },
      {
        title: "Projekte",
        description: "Eine Übersicht ausgewählter Projekte.",
        icon: "mdi-view-dashboard",
        href: "/projects",
        color: "blue--text"
      },
      {
        title: "Kompetenzen",
        description: "Eine Übersicht ausgewählter Kenntnisse.",
        icon: "mdi-align-horizontal-left",
        href: "/skills",
        color: "yellow--text"
      },
      {
        title: "Beruflicher Werdegang",
        description: "Meine beruflichen Stationen.",
        icon: "mdi-airplane-takeoff",
        href: "/career",
        color: "pink--text"
      },
      {
        title: "Ausbildung",
        description: "Meine Ausbildung und Qualifikationen.",
        icon: "mdi-certificate-outline",
        href: "/education",
        color: "indigo--text"
      }
    ]
  }),
  methods: {
    onSelect(href: string): void {
      this.$router.push(href);
    }
  }
});
