import { GetterTree } from "vuex";
import { State, Project, Skill, Rating } from "./types";

function skillRating(skill: Skill, projects: Project[]): Rating[] {
  const pCount = projects.reduce((acc: any, x: any) => {
    let value = x[skill.key];
    if (value) {
      if (!Array.isArray(value)) value = [value];
      for (const k of value) {
        if (acc[k]) acc[k] += 1;
        else acc[k] = 1;
      }
    }
    return acc;
  }, {});

  const ratings = skill.values.reduce((acc: any, x: Rating) => {
    acc[x.title] = x.rating;
    return acc;
  }, {});

  return [...new Set([...Object.keys(pCount), ...Object.keys(ratings)])].map(
    (key: string) => {
      if (ratings[key]) {
        return { title: key, rating: ratings[key] };
      } else {
        return {
          title: key,
          rating: pCount[key] > 3 ? 5.0 : pCount[key] > 1 ? 4.0 : 3.0
        };
      }
    }
  );
}

function includesValue(obj: any, key: string, value: string) {
  return Array.isArray(obj[key]) ? obj[key].includes(value) : obj[key] == value;
}

// const filterKeys = [
//   "industry",
//   "client",
//   "role",
//   "coding",
//   "framework",
//   "database",
//   "focus",
//   "os",
//   "product"
// ];

export const getters: GetterTree<State, any> = {
  filterKeys(state) {
    return state.skills.map(x => x.key);
  },
  skillKeys(state, getters) {
    return getters.filterKeys.filter(
      (x: string) => !["lang", "client", "role", "industry"].includes(x)
    );
  },
  filteredProjects(state) {
    const filters: any = state.projectFilters;
    return state.projects.filter(x => {
      const keys = Object.keys(filters).filter(
        key => filters[key] && filters[key].length > 0
      );
      if (keys.length == 0) {
        return true;
      } else {
        return keys.some(key => {
          if (!filters[key].some((f: string) => includesValue(x, key, f))) {
            return false;
          } else {
            return true;
          }
        });
      }
    });
  },
  extendedSkills(state, getters) {
    return state.skills.map((skill: Skill) => {
      skill.values = skillRating(skill, state.projects);
      return skill;
    });
    // return [
    //   ...state.skills,
    //   {
    //     key: "industry",
    //     title: "Branchen",
    //     icon: "mdi-gesture-tap",
    //     values: skillRating(state.projects, "industry")
    //   },
    //   {
    //     key: "role",
    //     title: "Rollen",
    //     values: skillRating(state.projects, "role")
    //   },
    //   {
    //     key: "client",
    //     title: "Kunden",
    //     values: skillRating(state.projects, "client")
    //   }
    // ];
  },
  skillCategories(state, getters) {
    return getters.extendedSkills.reduce((acc: any, x: any) => {
      for (const value of x.values) {
        acc[value.title] = x.key;
      }
      return acc;
    }, {});
  },
  skillProjects(state, getters) {
    const filterKeys = getters.skillKeys;
    return state.projects.reduce((acc: any, x: any) => {
      for (let value of filterKeys
        .map((key: string) => x[key])
        .filter((v: string) => v)) {
        value = Array.isArray(value) ? value : [value];
        for (const key of value) {
          if (acc[key]) acc[key].push(x);
          else acc[key] = [x];
        }
      }
      return acc;
    }, {});
  },
  skillFilters(state, getters) {
    const categories = getters.skillCategories;

    return getters.extendedSkills
      .filter((v: Skill) => !v.ignoreFilter)
      .map((x: Skill) => {
        const values =
          x.key == "products"
            ? [
                ...x.values,
                ...Object.keys(getters.skillProjects)
                  .filter(x => !categories[x])
                  .map(a => ({
                    title: a
                  }))
              ]
            : x.values;
        return {
          key: x.key,
          title: x.title,
          values: values
            .map((v: any) => v.title)
            .sort((a: string, b: string) => a.localeCompare(b))
        };
      });
  }
};
