

































import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: null
    };
  },
  computed: {
    ...mapState(["skills", "projectFilters"]),
    ...mapGetters(["skillFilters", "extendedSkills"])
  },
  methods: {
    onChange(key: string, values: string[]) {
      this.$store.dispatch("updateFilter", { [key]: values });
    }
  }
});
