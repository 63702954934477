import { MutationTree } from "vuex";
import { State, Message } from "./types";

export const mutations: MutationTree<State> = {
  FILTER_UPDATED(state, filter) {
    state.projectFilters = { ...state.projectFilters, ...filter };
  },
  FILTER_SET(state, filter) {
    state.projectFilters = { ...filter };
  },
  MESSAGE_CLEARED(state) {
    state.message = null;
  },
  MESSAGE_SET(state, msg: Message) {
    state.message = msg;
  }
};
