import { ActionTree } from "vuex";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { s2ab, projectData } from "@/utils/excel";
import { State, Project, Message } from "./types";

export const actions: ActionTree<State, any> = {
  updateFilter({ commit }, filter) {
    commit("FILTER_UPDATED", filter);
  },
  setFilter({ commit }, filter) {
    commit("FILTER_SET", filter);
  },
  setPredefinedFilter({ commit, state }, code) {
    const filter = state.predefinedFilters.find((x) => x.code == code);
    if (filter) {
      commit("FILTER_SET", filter.filters);
      commit("MESSAGE_SET", filter.message);
    }
  },
  resetFilters({ commit, state }) {
    commit("FILTER_SET", []);
  },
  clearMessage({ commit }) {
    commit("MESSAGE_CLEARED");
  },
  setMessage({ commit }, msg: Message) {
    commit("MESSAGE_SET", msg);
  },
  exportProjects(_, projects: Project[]) {
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Markus Eßer",
      Subject: "Projekte",
      Author: "Markus Eßer",
      CreatedDate: new Date(),
    };
    wb.SheetNames.push("Projekte");
    const ws = XLSX.utils.aoa_to_sheet(projectData(projects));
    wb.Sheets["Projekte"] = ws;
    const wscols = [
      { wch: 16 },
      { wch: 50 },
      { wch: 20 },
      { wch: 60 },
      { wch: 50 },
      { wch: 40 },
      { wch: 30 },
    ];

    ws["!cols"] = wscols;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "projects__markus_esser.xlsx"
    );
  },
};
