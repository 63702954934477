



















































































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import ProjectFilter from "@/views/Projects/ProjectFilter.vue";
import { version } from "../package.json";

export default Vue.extend({
  components: {
    ProjectFilter,
  },
  props: {
    source: String,
  },
  data() {
    return {
      drawer: null,
      projectFilterDrawer: true,
      version: version,
    };
  },
  computed: {
    ...mapState(["name", "message"]),
    ...mapGetters(["filteredProjects"]),
    fullName() {
      const { firstName, lastName } = this.name;
      return `${firstName} ${lastName}`;
    },
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    (this as any).$vuetify.theme.dark = true;
    document.title = this.$route.meta.title || "Markus Eßer";
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Markus Eßer";
    },
  },
  methods: {
    downloadProjects() {
      this.$store.dispatch("exportProjects", this.filteredProjects);
    },
    onClearMessage() {
      this.$store.dispatch("clearMessage");
    },
  },
});
