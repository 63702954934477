import { Project } from "@/store/types";

export function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}

export function projectData(projects: Project[]) {
  const result = [
    [
      "ZEITRAUM",
      "PROJEKT",
      "ROLLE",
      "BESCHREIBUNG",
      "KENNTNISSE",
      "PRODUKTE",
      "KUNDE",
    ],
  ];
  for (const project of projects) {
    const skills = [];
    if (project.coding) skills.push(...project.coding.sort());
    if (project.framework) skills.push(...project.framework.sort());
    if (project.database) skills.push(...project.database.sort());
    if (project.os) skills.push(...project.os.sort());
    if (project.focus) skills.push(...project.focus.sort());
    if (project.other) skills.push(...project.other.sort());
    result.push([
      project.period.join(" - "),
      project.title,
      project.role.join(", "),
      project.abstract + " " + project.description,
      skills.join(", "),
      project.product ? project.product.join(", ") : "",
      project.client,
    ]);
  }
  return result;
}
