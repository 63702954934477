import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Markus Eßer - Home"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/personal",
    name: "PersonalData",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PersonalData.vue")
  },
  {
    path: "/skills",
    name: "Skills",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Skills/index.vue")
  },
  {
    path: "/education",
    name: "Education",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Education.vue")
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects/index.vue")
  },
  {
    path: "/project/:code",
    name: "Project",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects/index.vue")
  },
  {
    path: "/career",
    name: "Career",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Career.vue")
  },
  {
    path: "/aboutme",
    name: "AboutMe",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutMe.vue")
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
