import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import marked from "marked";

Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
// } */,
  marked
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
